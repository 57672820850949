import { a as INTAKE_STATUS_TEXTS, I as INTAKE_STATUS_COLORS } from "./intake.status.js";
import { MAINTAIN_PERMISSION, DELETE_PERMISSION, MOVE_PERMISSION, GROWERS_SAMPLEPROCESSING } from "@kraftheinz/common";
import { n as normalizeComponent } from "./vueComponentNormalizer.js";
var render$1 = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", {
    staticClass: "edit-intake-samples"
  }, [_c("bread-crumb", {
    attrs: {
      "items": _vm.itemsMenu
    }
  }), _vm.entity ? _c("div", {
    staticClass: "header px-5 pt-3 pb-4 mb-5 d-flex justify-content-between align-items-center"
  }, [_c("div", {
    staticClass: "d-flex align-items-center flex-wrap"
  }, [_c("a-icon", {
    staticClass: "arrow-left pr-3",
    attrs: {
      "type": "arrow-left"
    },
    on: {
      "click": function($event) {
        return _vm.onBack();
      }
    }
  }), _c("span", {
    staticClass: "pr-3 header-title mb-0"
  }, [_vm._v(" Intake - " + _vm._s(_vm.entity.intakeNumber) + " ")]), _c("a-tag", {
    attrs: {
      "color": _vm.getStatusColor(_vm.entity.status)
    }
  }, [_vm._v(" " + _vm._s(_vm.parseStatus(_vm.entity.status)) + " ")])], 1), _vm.hasMaintainPermission ? _c("a-button", {
    attrs: {
      "type": "primary",
      "loading": _vm.isSubmitting,
      "disabled": _vm.hasLastSample
    },
    on: {
      "click": _vm.addSample
    }
  }, [_vm._v(" Add Sample ")]) : _vm._e()], 1) : _vm._e(), _c("div", {
    staticClass: "body mx-5"
  }, [_c("a-row", {
    attrs: {
      "gutter": 24,
      "type": "flex"
    }
  }, [_c("a-col", {
    attrs: {
      "lg": 24,
      "xl": 8
    }
  }, [_c("a-card", {
    style: {
      height: "100%"
    }
  }, [_c("div", {
    staticClass: "d-flex flex-column",
    style: {
      height: "100%"
    }
  }, [_c("div", {
    staticClass: "d-flex align-items-center justify-content-between flex-wrap mb-auto"
  }, [_c("h2", {
    staticClass: "intake-title"
  }, [_vm._v("Intake - " + _vm._s(_vm.entity.intakeNumber))]), _c("div", {
    staticClass: "status-filter"
  }, [_c("select-input", {
    attrs: {
      "source": "value",
      "source-label": "text",
      "data-source": _vm.statusFilter,
      "value": _vm.entity.status,
      "disabled": !_vm.hasMaintainPermission
    },
    on: {
      "change": _vm.changeStatus
    }
  })], 1)]), _vm.entity ? _c("div", [_c("div", [_vm._v(" Last modified by " + _vm._s(_vm.entity.updatedBy) + " on " + _vm._s(_vm._f("moment")(_vm.entity.updatedAt, "DD/MM/YYYY HH:mm")) + " ")])]) : _vm._e()])])], 1), _c("a-col", {
    staticClass: "kh-edit-sample-info",
    attrs: {
      "lg": 24,
      "xl": 16
    }
  }, [_vm.entity ? _c("a-card", [_c("h6", {
    staticClass: "h6 mb-3"
  }, [_vm._v("Intake Information")]), _c("a-row", {
    attrs: {
      "gutter": [24, 24]
    }
  }, [_c("a-col", {
    attrs: {
      "lg": 24,
      "xl": 12
    }
  }, [_c("text-input", {
    attrs: {
      "label": "Grower Code",
      "disabled": true,
      "value": _vm.entity.growerCode + " - " + _vm.entity.grower,
      "span": 12
    }
  })], 1), _c("a-col", {
    attrs: {
      "lg": 24,
      "xl": 12
    }
  }, [_c("text-input", {
    attrs: {
      "label": "Contract Code",
      "disabled": true,
      "value": _vm.entity.contractCode + " - " + _vm.entity.contract,
      "span": 12
    }
  })], 1), _c("a-col", {
    attrs: {
      "lg": 24,
      "xl": 12
    }
  }, [_c("text-input", {
    attrs: {
      "label": "Paddock Code",
      "disabled": true,
      "value": _vm.entity.paddockCode + " - " + _vm.entity.paddock,
      "span": 12
    }
  })], 1), _c("a-col", {
    attrs: {
      "lg": 24,
      "xl": 12
    }
  }, [_c("text-input", {
    key: "crop",
    attrs: {
      "label": "Crop",
      "disabled": true,
      "value": _vm.entity.cropCode + " " + _vm.entity.crop,
      "span": 12
    }
  })], 1)], 1)], 1) : _vm._e()], 1)], 1), _c("div", {
    staticClass: "list-samples"
  }, [_c("h6", {
    staticClass: "h6 mt-5 mb-3"
  }, [_vm._v("Samples")]), _c("a-select", {
    staticClass: "filter-select mb-3",
    staticStyle: {
      "width": "135px"
    },
    attrs: {
      "value": _vm.activeFilter
    },
    on: {
      "change": _vm.onChangeFilter
    }
  }, _vm._l(_vm.options, function(option) {
    return _c("a-select-option", {
      key: option.value,
      attrs: {
        "value": option.value
      }
    }, [_c("a-icon", {
      attrs: {
        "type": option.iconType,
        "theme": option.iconTheme
      }
    }), _vm._v(" " + _vm._s(option.text) + " ")], 1);
  }), 1), _c("a-table", {
    staticClass: "mb-4",
    attrs: {
      "columns": _vm.columns,
      "data-source": _vm.dataFilter,
      "row-key": "id",
      "loading": _vm.entity.length === 0,
      "custom-row": _vm.customRow,
      "scroll": {
        x: _vm.scrollX
      },
      "pagination": false,
      "bordered": ""
    },
    scopedSlots: _vm._u([_vm._l(["id", "sampleNumber", "sampleType", "lastSample", "operator", "grossWeight"], function(col) {
      return {
        key: col,
        fn: function(text, record) {
          return [_c("div", {
            key: col
          }, [col === "sampleNumber" ? [_c("router-link", {
            attrs: {
              "to": _vm.redirectItem(record)
            }
          }, [_vm._v(_vm._s(text))])] : col === "lastSample" ? [record.lastSample ? [_c("a-icon", {
            attrs: {
              "type": "check-circle"
            }
          })] : _vm._e()] : [_vm._v(" " + _vm._s(text) + " ")]], 2)];
        }
      };
    }), {
      key: "action",
      fn: function(text, record) {
        return [_c("div", {
          staticClass: "editable-row-action d-flex justify-content-center"
        }, [[_vm.hasDeletePermission ? _c("a-tooltip", {
          scopedSlots: _vm._u([{
            key: "title",
            fn: function() {
              return [_vm._v(" " + _vm._s(record.isInactive ? "Activate" : "Deactivate") + " ")];
            },
            proxy: true
          }], null, true)
        }, [_c("a-button", {
          attrs: {
            "icon": record.isInactive ? "eye-invisible" : "eye",
            "size": "small",
            "type": "link"
          },
          on: {
            "click": function($event) {
              return _vm.onToggleActiveClick(record);
            }
          }
        })], 1) : _vm._e(), _vm.hasMovePermission ? _c("a-tooltip", {
          scopedSlots: _vm._u([{
            key: "title",
            fn: function() {
              return [_vm._v("Move Sample")];
            },
            proxy: true
          }], null, true)
        }, [_c("a-button", {
          attrs: {
            "icon": "interaction",
            "size": "small",
            "type": "link",
            "disabled": record.isInactive
          },
          on: {
            "click": function($event) {
              return _vm.moveSample(record);
            }
          }
        })], 1) : _vm._e(), _c("a-tooltip", {
          scopedSlots: _vm._u([{
            key: "title",
            fn: function() {
              return [_vm._v("Detail")];
            },
            proxy: true
          }], null, true)
        }, [_c("a-button", {
          attrs: {
            "icon": "right-circle",
            "size": "small",
            "type": "link"
          },
          on: {
            "click": function($event) {
              _vm.$router.push(_vm.redirectItem(record));
            }
          }
        })], 1)]], 2)];
      }
    }], null, true)
  })], 1), _c("a-modal", {
    attrs: {
      "title": "Move Sample",
      "centered": "",
      "ok-button-props": {
        props: {
          disabled: !_vm.moveIntakeId
        }
      }
    },
    on: {
      "ok": _vm.submitModal
    },
    model: {
      value: _vm.visibleModal,
      callback: function($$v) {
        _vm.visibleModal = $$v;
      },
      expression: "visibleModal"
    }
  }, [_c("div", {
    staticClass: "move-sample"
  }, [_c("a-row", {
    attrs: {
      "gutter": [24, 24]
    }
  }, [_c("a-col", {
    attrs: {
      "lg": 24,
      "xl": 12
    }
  }, [_c("text-input", {
    attrs: {
      "label": "Current Intake",
      "disabled": true,
      "value": "Intake - " + _vm.entity.id,
      "span": 12
    }
  })], 1), _c("a-col", {
    attrs: {
      "lg": 24,
      "xl": 12
    }
  }, [_c("select-input", {
    attrs: {
      "label": "Select Intake to Move",
      "data-source": _vm.commonIntake,
      "source": "id",
      "source-label": _vm.genIntakeLabel
    },
    on: {
      "change": _vm.onIntakeChange
    }
  })], 1), _c("a-col", {
    attrs: {
      "lg": 24,
      "xl": 12
    }
  }, [_c("text-input", {
    key: "crop",
    attrs: {
      "label": "Sample Number",
      "disabled": true,
      "value": _vm.sampleModal.sampleNumber,
      "span": 12
    }
  })], 1), _c("a-col", {
    attrs: {
      "lg": 24,
      "xl": 12
    }
  }, [_c("text-input", {
    attrs: {
      "label": "Sample Type",
      "disabled": true,
      "value": _vm.sampleModal.sampleTypeCode + " - " + _vm.sampleModal.sampleType,
      "span": 12
    }
  })], 1)], 1)], 1)])], 1)], 1);
};
var staticRenderFns$1 = [];
var EditIntakeSamples_vue_vue_type_style_index_0_scoped_true_lang = "";
const __vue2_script$1 = {
  name: "EditIntakeSamples",
  inject: ["resourceProps", "can"],
  data() {
    const [
      intakeSampleProps,
      sampleDetailsProps,
      sampleSttProps
    ] = this.resourceProps;
    const apiUrl2 = "#{VUE_APP_API_URL}#";
    return {
      intakeSampleProps,
      sampleDetailsProps,
      sampleSttProps,
      apiUrl: apiUrl2,
      customUrl: `${apiUrl2}/grower/intake-samples/${this.$route.params.id}/samples`,
      isSubmitting: false,
      entity: {},
      field: "Status",
      statusFilter: [
        {
          value: "E"
        },
        {
          value: "S"
        },
        {
          value: "A"
        }
      ],
      itemsMenu: [
        {
          key: "sampleprocessing",
          title: "Sample Processing",
          path: ""
        },
        {
          key: "intake-samples",
          title: "Intake list",
          path: "/watties-grower/intake-samples"
        }
      ],
      scrollX: 0,
      columns: [],
      data: [],
      dataActive: [],
      dataInactive: [],
      dataFilter: [],
      activeFilter: "active",
      options: [
        {
          text: "All",
          value: "all",
          iconType: "container",
          iconTheme: "twoTone"
        },
        {
          text: "Active",
          value: "active",
          iconType: "eye",
          iconTheme: "twoTone"
        },
        {
          text: "Inactive",
          value: "inactive",
          iconType: "eye-invisible",
          iconTheme: "outlined"
        }
      ],
      hasLastSample: false,
      sampleId: null,
      visibleModal: false,
      loadingModal: true,
      sampleModal: {},
      commonIntake: [],
      moveIntakeId: null
    };
  },
  computed: {
    hasMaintainPermission() {
      return this.can(MAINTAIN_PERMISSION);
    },
    hasDeletePermission() {
      return this.can(DELETE_PERMISSION);
    },
    hasMovePermission() {
      return this.can(MOVE_PERMISSION);
    }
  },
  async mounted() {
    this.isTabletScreen = window.matchMedia("(max-width: 1024px)").matches ? true : false;
    await this.intakeSampleProps.crud.fetchEntity(this.customUrl), this.entity = this.intakeSampleProps.crud.getEntity();
    this.storeValues(this.entity);
    this.itemsMenu = [
      ...this.itemsMenu,
      {
        key: `${this.entity && this.entity.id}`,
        title: `Intake - ${this.entity && this.entity.intakeNumber}`,
        path: `/watties-grower/intake-samples/${this.entity && this.entity.id}`
      }
    ];
    this.data = this.entity.samples;
    this.hasLastSample = this.data.filter((item) => item.lastSample && !item.isInactive).length > 0;
    this.updateDataFilter();
    this.createdStatusFilter();
    this.columns = [
      {
        title: "Sample Number",
        dataIndex: "sampleNumber",
        width: 150,
        fixed: this.isTabletScreen ? "left" : false,
        scopedSlots: { customRender: "sampleNumber" }
      },
      {
        title: "Sample Type",
        dataIndex: "sampleType",
        width: 150,
        scopedSlots: { customRender: "sampleType" }
      },
      {
        title: "Last Sample",
        dataIndex: "lastSample",
        align: "center",
        width: 140,
        scopedSlots: { customRender: "lastSample" }
      },
      {
        title: "Operator Code",
        dataIndex: "operator",
        scopedSlots: { customRender: "operator" }
      },
      {
        title: "Gross Weight",
        dataIndex: "grossWeight",
        width: 160,
        scopedSlots: { customRender: "grossWeight" }
      },
      {
        title: "Action",
        dataIndex: "action",
        width: 90,
        fixed: this.isTabletScreen ? "right" : false,
        scopedSlots: { customRender: "action" }
      }
    ];
  },
  methods: {
    storeValue(resourceKey, value) {
      this.editForm = {
        ...this.editForm,
        [resourceKey]: value
      };
      this.intakeSampleProps.crud.updateEntity("editForm", {
        resourceKey,
        value
      });
    },
    createdStatusFilter() {
      if (this.entity.status === "S")
        this.statusFilter.shift();
      if (this.entity.status === "A")
        this.statusFilter.splice(0, 2);
      this.statusFilter.map((key) => {
        let colorStt = key.value === "A" ? "#15B982" : key.value === "E" ? "#EB2F96" : null;
        Object.assign(key, {
          text: INTAKE_STATUS_TEXTS[key.value],
          color: colorStt ? colorStt : INTAKE_STATUS_COLORS[key.value]
        });
      });
    },
    storeValues(entity) {
      Object.entries(entity).forEach(([resourceKey, value]) => this.storeValue(resourceKey, value));
    },
    onBack() {
      this.$router.push("/watties-grower/intake-samples");
    },
    getStatusColor(value) {
      return INTAKE_STATUS_COLORS[value];
    },
    parseStatus(value) {
      return INTAKE_STATUS_TEXTS[value];
    },
    changeStatus(e) {
      if (e === this.entity.status)
        return;
      this.sampleSttProps.crud.updateEntity("editForm", {
        resourceKey: "status",
        value: e
      });
      this.sampleSttProps.crud.submitEntity("replace", `${this.apiUrl}/grower/intakes/${this.entity.id}/status`).then(() => {
        this.intakeSampleProps.crud.fetchEntity(this.customUrl).then((res) => {
          this.entity = res.data;
          this.storeValues(this.entity);
          this.createdStatusFilter();
        });
        this.storeValues(this.entity);
      }, (err) => console.log(err));
    },
    toggleActive(record) {
      return () => {
        let valInactive = !record.isInactive;
        this.intakeSampleProps.crud.submitPartialEntity({
          resourceName: this.intakeSampleProps.resourceName,
          id: record.id,
          customUrl: this.customUrl ? `${this.customUrl}/${record.id}` : null,
          data: [
            {
              op: "replace",
              path: "/IsInactive",
              value: valInactive
            }
          ]
        }).then(() => {
          this.data.forEach((m) => m.isInactive = m.id === record.id ? valInactive : m.isInactive);
          this.updateDataFilter();
          this.cacheData = this.data;
        });
      };
    },
    onToggleActiveClick(record) {
      let icon, okType, title;
      if (record.isInactive) {
        title = "Do you want to Activate this item?";
        icon = "eye";
        okType = "primary";
      } else {
        title = "Do you want to Deactivate this item?";
        icon = "eye-invisible";
        okType = "danger";
      }
      this.$confirm({
        class: "list__modal-confirm",
        icon,
        title,
        cancelText: "No",
        okText: "Yes",
        okType,
        onOk: this.toggleActive(record)
      });
    },
    updateDataFilter() {
      this.hasLastSample = this.data.filter((item) => item.lastSample && !item.isInactive).length > 0;
      this.dataActive = this.data.filter((item) => item.isInactive === false);
      this.dataInactive = this.data.filter((item) => item.isInactive === true);
      this.onChangeFilter(this.activeFilter);
    },
    onChangeFilter(value) {
      this.activeFilter = value;
      switch (value) {
        case "active":
          this.dataFilter = this.dataActive;
          break;
        case "inactive":
          this.dataFilter = this.dataInactive;
          break;
        default:
          this.dataFilter = this.data;
      }
    },
    customRow(record) {
      if (record.isInactive) {
        return { class: "list__item--inactive" };
      }
      return {};
    },
    addSample() {
      this.isSubmitting = true;
      this.intakeSampleProps.crud.updateEntity("createForm", {});
      this.intakeSampleProps.crud.submitEntity("create", this.customUrl).then((response) => {
        this.$router.push(`/watties-grower/intake-samples/${this.entity.id}/samples/?sampleId=${response.data.id}&status=${this.entity.status}&type=create`);
      });
    },
    moveSample(record) {
      this.sampleModal = {};
      this.sampleId = record.id;
      this.sampleModal = this.data.filter((item) => item.id === record.id)[0];
      this.axios.get(`${this.apiUrl}/grower/common/intakes?Status=S&Status=E`).then((res) => {
        this.commonIntake = res.data;
      }, (err) => console.log(err)).finally(() => {
        this.visibleModal = true;
      });
    },
    storeSamplesValue(value, resourceKey) {
      if (!resourceKey.includes(",")) {
        this.sampleDetailsProps.crud.updateEntity("editForm", {
          resourceKey,
          value
        });
        return;
      }
      const resourceKeys = resourceKey.split(",");
      resourceKeys.forEach((key) => this.sampleDetailsProps.crud.updateEntity("editForm", {
        resourceKey: key,
        value: key === "operatorId" ? value["operator"] : value[key]
      }));
    },
    submitModal() {
      this.storeSamplesValue(this.sampleModal, "grossWeight,isInactive,lastSample,operatorId");
      this.sampleDetailsProps.crud.submitEntity("replace", `${this.apiUrl}/grower/intake-samples/${this.entity.id}/samples/${this.sampleModal.id}`).then(() => {
        this.intakeSampleProps.crud.fetchEntity(this.customUrl), this.entity = this.intakeSampleProps.crud.getEntity();
      }, (err) => console.log(err));
    },
    genIntakeLabel(option) {
      return `Intake - ${option.intakeNumber}`;
    },
    onIntakeChange(e) {
      this.moveIntakeId = e;
    },
    redirectItem(record) {
      return `/watties-grower/intake-samples/${this.entity.id}/samples/?sampleId=${record.id}&status=${this.entity.status}&type=edit`;
    }
  }
};
const __cssModules$1 = {};
var __component__$1 = /* @__PURE__ */ normalizeComponent(__vue2_script$1, render$1, staticRenderFns$1, false, __vue2_injectStyles$1, "7c104d49", null, null);
function __vue2_injectStyles$1(context) {
  for (let o in __cssModules$1) {
    this[o] = __cssModules$1[o];
  }
}
var EditIntakeSamples = /* @__PURE__ */ function() {
  return __component__$1.exports;
}();
var render = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", {
    staticClass: "edit-intake-samples-containers"
  }, [_c("resource", {
    attrs: {
      "name": ["grower.intake-samples.samples", "grower.intake-samples.details", "grower.intake-samples.status"],
      "api-url": _vm.apiUrl,
      "edit": _vm.EditIntakeSamples,
      "redirect-route": ["/watties-grower/intake-samples", "", ""],
      "edit-route": _vm.editRoute,
      "page": _vm.page
    }
  }), _c("router-view")], 1);
};
var staticRenderFns = [];
const apiUrl = "#{VUE_APP_API_URL}#";
const __vue2_script = {
  name: "EditIntakeSamples",
  data() {
    const id = this.$route.params.id;
    return {
      EditIntakeSamples,
      apiUrl,
      editRoute: `/watties-grower/intake-samples/${id}/samples/:id`,
      page: GROWERS_SAMPLEPROCESSING
    };
  }
};
const __cssModules = {};
var __component__ = /* @__PURE__ */ normalizeComponent(__vue2_script, render, staticRenderFns, false, __vue2_injectStyles, null, null, null);
function __vue2_injectStyles(context) {
  for (let o in __cssModules) {
    this[o] = __cssModules[o];
  }
}
var index = /* @__PURE__ */ function() {
  return __component__.exports;
}();
export { index as default };
